<template>
	<div class="notice_info">
		<div class="notice-wrap">
			<el-breadcrumb separator="/" class="path">
				<el-breadcrumb-item :to="{ path: '/' }" class="path-home">
					<i class="n el-icon-s-home"></i>首页
				</el-breadcrumb-item>
				<el-breadcrumb-item class="path-help">站内信</el-breadcrumb-item>
			</el-breadcrumb>
			<div class="notice" v-loading="loading">
				<!-- <div class="menu">
					<div class="title">最新消息</div>
					<div class="item" v-for="item in masterLists" :key="item.id" @click="detil(item.id)">
						<div class="item-name" @click="menuOther(item.id)">{{ item.title }}</div>
					</div>
				</div> -->
				<div class="list-wrap">
					<div class="notice-title">
						站内信息
						<div class="master-seed">
							<div class="all-read" @click="getEditallSeed()">
								<span class="iconfont iconqingchu font-size-tag color-tip"></span>
								<span class="font-size-tag color-tip">全部已读</span>
							</div>
						</div>
					</div>
					<div class="empty" v-if="masterLists.length ==  0">
						<div class="empty_img">
							<img :src="$util.img('upload/uniapp/sitemessage/empty.png')" alt="" />
						</div>
						<div class="color-tip margin-top margin-bottom">暂无信息</div>
					</div>
					<div v-for="(item, index) in masterLists" :key="index" v-else>
						<div class="master-item-wrap">
							<div class="master-item-box" :style="[Tactive == index ? leftStyle : '']" @click="toDetail(item.sub_type,item.id,item.site_id,item)">
								<div class="master-item">
									<div class="item-icon iconfont "
										@click="toDetail(item.sub_type,item.id,item.site_id,item)"
										:class="[{'order': item.sub_type == 'order'}, {'servicer': item.sub_type == 'servicer'}, {'delivery': item.sub_type == 'delivery'}, {'group': item.sub_type == 'group'}, {'promotion': item.sub_type == 'promotion'}, {'account': item.sub_type == 'account'}]"
									></div>
									
									<div class="item-detail" >
										<div class="master-detail">
											<span v-if="item.sub_type == 'servicer'">{{item.title}}</span>
											<span v-else-if="item.sub_type == 'account'">{{staticdata.account}}</span>
											<span v-else-if="item.sub_type == 'delivery' ">{{staticdata.delivery}}</span>
											<span v-else-if="item.sub_type == 'group' ">{{staticdata.group}}</span>
											<span v-else-if="item.sub_type == 'order' ">{{staticdata.order}}</span>
											<span v-else-if="item.sub_type == 'promotion' ">{{staticdata.promotion}}</span>
											<span class="time font-size-tag color-tip" v-if="item.send_time != '' ">{{item.time}}</span>
										</div>
					
										<div class="master-presentation">
											<span v-if="item.sub_type == 'servicer'" class="richspan" v-html="item.content"></span>
											<span v-else class="font-size-tag color-sub">{{item.content}}</span>
											
											<div v-if="item.remind_num > 0" class="order-unread color-base-bg">{{item.remind_num}}</div>
											<div v-else-if="item.remind_num > 99" class="order-unread color-base-bg">99+</div>
										</div>
									</div>
								</div>
								<div @click.stop="getDeleteMemberMessage(item.id, item.site_id)" class="delete color-base-bg">删除</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<servicerMessage v-if="showKef" ref="servicerMessage" class="kefu" :shop="{shop_id:kefuInfo.site_id,shop_name:kefuInfo.title}"></servicerMessage>
			<!-- <div class="pager">
				<el-pagination background :pager-count="5" :total="total" prev-span="上一页" next-span="下一页"
					:current-page.sync="queryinfo.page" :page-size.sync="queryinfo.page_size"
					@size-change="handlePageSizeChange" @current-change="handleCurrentPageChange" hide-on-single-page>
				</el-pagination>
			</div> -->
		</div>
		
	</div>
</template>

<script>
	
	import {
		sitemessageLists,
		staticdata,
		deleteMemberMessage,
		editallseed
	} from "@/api/cms/master";
	import {
		shopServiceOpen
	} from "@/api/website.js"
	import servicerMessage from "@/components/message/servicerMessage";
	export default {
		name: "notice",
		components: {
			servicerMessage
		},
		data: () => {
			return {
				loading:true,
				masterLists: [],
				staticdata: {},
				deleteMemberMessage: {},
				recordsSeed: {},
				seed: {},
				touchId: '',
				flag: true,
				moveClass: '',
				statusList: [],
				orderStatus: 0,
				nav_type: 'other',
				time: '',
				showKef:false,
				
				startX: null, //起始位置
				startM: null,
				startE: null, //结束位置
				left: 0,
				deleteBtn: 120,
				leftStyle: {},
				rightStyle: {},
				Tactive: 0,
				selectIndex: 0,
				isLeftSide: 0,
				kefuInfo:{}
			};
		},
		created() {
			this.getSitemessageLists()
			this.getStaticdata()
			this.getOrderStatus()
		},
		methods: {
			getOrderStatus() {
				this.statusList = [{
						status: 0,
						name: '服务号',
						id: 'status_0',
						sub_type: 'other'
					},
					{
						status: 1,
						name: '官方客服',
						id: 'status_0',
						sub_type: 'servicer'
					}
				];
			},
			init() {
				this.leftStyle = {};
				this.rightStyle = {};
			},
			getSitemessageLists() {
				sitemessageLists()
					.then(res => {
						if (res.code == 0 && res.data) {
							for (var i = 0; i < res.data.list.length; i++) {
								res.data.list[i].send_time = this.$util.timeStampTurnTime(res.data.list[i].send_time)
								res.data.list[i].time = this.$util.getSitemessageDate(res.data.list[i].send_time)
							}
							this.masterLists = res.data.list
							console.log(this.masterLists)
						}
						this.loading = false;
					})
					.catch(err => {
						this.loading = false;
						this.$message.error(err.message);
					});
			},
			getStaticdata() {
				staticdata()
					.then(res => {
						if (res.code == 0 && res.data) {
							this.staticdata = res.data.sub_type
						}
						this.loading = false;
					})
					.catch(err => {
						this.loading = false;
						this.$message.error(err.message);
					});
			},
			getDeleteMemberMessage(id, site_id) {
				deleteMemberMessage({
						id: id,
						site_id: site_id
					})
					.then(res => {
						if (res.code == 0 ) {
							this.$message(res.message);
							this.deleteMemberMessage = res.data.sub_type
							this.getSitemessageLists()
							
						}
						this.loading = false;
					})
					.catch(err => {
						this.loading = false;
						this.$message.error(err.message);
					});
			},
			getEditallSeed() {
				let arr = [];
				for (var i = 0; i < this.masterLists.length; i++) {
					arr.push(this.masterLists[i].sub_type)
				}
				deleteMemberMessage({
						sub_type: arr.toString()
					})
					.then(res => {
						if (res.code == 0 ) {
							this.$message(res.message);
							this.editallSeed = res.data
							this.getSitemessageLists()
						}
						this.loading = false;
					})
					.catch(err => {
						this.loading = false;
						// this.$message.error(err.message);
					});
			},
			// 跳转
			toDetail(sub_type, id, site_id,data) {
				this.kefuInfo = data
				this.showKef = true
				console.log(sub_type,id,site_id)
				if (sub_type == 'servicer') {
					shopServiceOpen().then((res) => {
						if (res.code == 0) {
							if (res.data.type == 'third') {
								window.open(res.data.third, "_blank");
								this.getEditallSeed()
							} else if (res.data.type == 'system') {
								console.log(this.$refs.servicerMessage)
								this.$refs.servicerMessage.show()
							}
						}
					})
				} else {
					this.$router.push({ path: '/cms/' + sub_type, query: { sub_type: sub_type,id:id,site_id:site_id } });
				}
			},
		}
	};
</script>
<style lang="scss" scoped>
	.notice_info {
		background-color: #fff;
	}

	.notice-wrap {
		width: 1200px;
		margin: 0 auto;
		background: #ffffff;

		.path {
			padding: 15px 0;
		}
	}

	.notice {
		background-color: #ffffff;
		min-height: 300px;
		position: relative;
		display: flex;

		.menu {
			width: 210px;
			min-height: 300px;
			background: #ffffff;
			border: 1px solid #f1f1ff;
			flex-shrink: 0;

			.title {
				padding-left: 16px;
				background: #f8f8f8;
				font-size: $ns-font-size-base;
				height: 40px;
				cursor: pointer;
				color: #666666;
				display: flex;
				align-items: center;
			}

			.item-name {
				font-size: $ns-font-size-base;
				cursor: pointer;
				line-height: 40px;
				border-top: 1px solid #f1f1f1;
				padding-left: 25px;
				padding-right: 10px;
				white-space: nowrap;
				overflow: hidden;
				span-overflow: ellipsis;
				height: 40px;
				background: #ffffff;
				color: #666666;

				&:hover {
					color: $base-color;
				}
			}

			.active {
				color: $base-color;
			}
		}

		.list-wrap {
			padding: 0 40px;
			border: 1px solid #f1f1f1;
			width: 100%;
			margin-bottom: 10px;

			.notice-title {
				position: relative;
				padding: 37px 0 20px 0;
				font-size: 18px;
				border-bottom: 1px dotted #e9e9e9;
			}

			.list {
				display: flex;
				justify-content: space-between;
				align-items: center;

				&:last-of-type {
					border-bottom: initial;
				}

				&:nth-child(2) {
					margin-top: 10px;
				}

				.item {
					font-size: $ns-font-size-base;
					white-space: nowrap;
					overflow: hidden;
					span-overflow: ellipsis;
					cursor: pointer;
					padding: 3px 0;

					&:hover {
						color: $base-color;
					}
				}

				.info {
					display: flex;
					font-size: $ns-font-size-base;

					.time {
						margin-right: 10px;
					}
				}
			}
		}
	}

	.page {
		span-align: center;
	}
	.master-seed{
		position: absolute;
		right: 30px;
		top: 37px;
		font-size: 14px;
		cursor: pointer;
		color: #ff7386;
	}
	.empty{
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		.empty_img{
			margin:  20px 0 0 0px;
		}
		.color-tip{
			margin: 15px 0;
		}
	}
	.master-item-box{
		margin-top: 20px;
		border-bottom: 1px solid #efefef;
		padding-bottom: 15px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		cursor: pointer;
		.master-item{
			display: flex;
			.item-icon{
				width: 60px;
				height: 60px;
				border-radius: 50%;
				display: flex;
				justify-content: center;
				align-items: center;
				color: #fff;
				font-size: 30px;
				margin-right: 25px;
			}
			.order {
				background-color: #3acbe1;
			}
			
			.servicer {
				background-color: #4091ff;
			}
			
			.account {
				background-color: #ff882e;
			}
			
			.delivery {
				background-color: #00ae66;
			}
			
			.group {
				background-color: red;
			}
			
			.promotion {
				background-color: #ff405b;
			}
			
			.promotion::before {
				content: "\e6d3";
			}
			
			.group::before {
				content: "\e66a";
			}
			
			.delivery::before {
				content: "\e70f";
			}
			
			.account::before {
				content: "\e619";
			}
			
			.order::before {
				content: "\e61c";
			}
			
			.servicer::before {
				content: "\e686";
			}
			.item-detail{
				.master-detail{
					display: flex;
					justify-content: space-between;
					.time{
						font-size: 12px;
						color: #8f8f8f;
						position: absolute;
						right: 30px;
					}
				}
			}
		}
		.delete{
			margin-right: 15px;
			color: red;
			cursor: pointer;
			margin-top: 20px;
		}
	}
</style>